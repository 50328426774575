<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-status v-if="getAccess('project.status')" :items="statusItems" @click="clickStatus($event)" :disabled="loading" />
      <a-btn-edit v-if="getAccess('project.edit', id)" @click="showEditDialog = true" :disabled="loading" />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id" :api="url"></edit-dialog>
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">фото объекта</div>
          </template>
          <a-view-images :value="data.photos" :style="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">основная информация</div>
          </template>
          <a-form-view :value="data" :model="[model[1], model[2], model[3], model[4], model[5], model[6], model[7], model[8]]" :config="{ dense: true }" class="block_master" />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="third">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{ tab.title }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px">
            <v-tab-item>
              <v-card-text style="height: 300px; overflow: auto">
                <v-fab-transition>
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('docs')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" :category="1" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, statusChange } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, statusChange],
  components: {
    editDialog: () => import("./../dialogs/projectDialog"),
  },
  data() {
    return {
      id: 0,
      showEditDialog: false,
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.projects,
      model: this.$store.getters["config/get"].models.projects.form,
      url: "/mechti/design-projects",
      tab: 0,
      tabs: [{ title: "Документация" }],
      blockHeight: 256,
    };
  },
  created() {
    this.$root.title = "Проекты";
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },
  mounted() {},

  computed: {
    statuses() {
      return this.m.statuses;
    },
    statusItems() {
      let items = [];
      let res = [];
      let status = {};
      let type;
      type = "status_site";
      status = this.getStatus(type);
      if (status?.["next"]) {
        res = [
          ...res,
          ...this.statuses.filter((s) => {
            return status.next.includes(s.value) && s.field == type;
          }),
        ];
      }

      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data.status_site && el.field == "status_site";
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
  },
  watch: {
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    getStatus(type) {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data[type] && el.field == type;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = !true;
    },
  },
};
</script>
